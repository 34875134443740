import React, { useState } from "react"
import { Form, InputGroup, ButtonGroup, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { useLanguage } from "../App";

export default function RSVP() {

  const history = useHistory();
  const { isChinese } = useLanguage();

  const passphrase = 'ellis';

  const placeholderOptions = isChinese ? [
    '梁朝偉',
    '蔡依林',
  ] : [
    'Taylor Swift',
    'Chris Stapleton',
    'Matt Murdock',
  ];
  const placeholderName = placeholderOptions[Math.floor(Math.random()*placeholderOptions.length)];

  const [passed, setPassed] = useState(false);  // whether or not they've entered the passphrase
  const [currentPassphrase, setCurrentPassphrase] = useState('');

  // Form responses
  const [name, setName] = useState('');
  const [willComeInPerson, setWillComeInPerson] = useState(null);  // null == not select yet, True, or False

  const [numberOfAttendees, setNumberOfAttendees] = useState('');
  const [dietaryRestrictions, setDietaryRestrictions] = useState('');
  const [comments, setComments] = useState('');
  const [zoomEmail, setZoomEmail] = useState('');

  // Should submit in this format:
  // https://docs.google.com/forms/d/e/1FAIpQLScaMgnaIHWb9tSzh0NrG_UYdZctQEP3sUiapvksEt_NHZChyg/formResponse?entry.1358167282=NAME&entry.1498135098=NUM&entry.2606285=DIETARY&entry.2090611883=COMMENTS&entry.717549045=ZOOMEMAIL

  if (!passed) {
    return (
      <div className="text-center">
        <Form style={{maxWidth: "26rem", marginTop: "10rem", marginLeft: "auto", marginRight: "auto"}}>
          <InputGroup className="m-3">
            <InputGroup.Text>{isChinese ? '密碼：' : 'Password:'}</InputGroup.Text>
            <Form.Control 
              onChange={(e) => {
                setCurrentPassphrase(e.target.value);
              }}
            />
          </InputGroup>
          <Button
            type="submit"
            variant="outline-info"
            onClick={() => {
              if (currentPassphrase.toLowerCase() === passphrase.toLowerCase()) {
                setPassed(true);
              }
            }}
          >
            {isChinese ? '下一步' : 'Submit'}
          </Button>
        </Form>
      </div>
    );
  }

  function submitForm({
    name,
    numberOfAttendees,
    dietaryRestrictions,
    comments,
    zoomEmail
  }) {
    const submissionURL = (
      'https://docs.google.com/forms/d/e/1FAIpQLScaMgnaIHWb9tSzh0NrG_UYdZctQEP3sUiapvksEt_NHZChyg/formResponse?' +
      `entry.1358167282=${encodeURIComponent(name)}` +
      `&entry.1498135098=${encodeURIComponent(numberOfAttendees)}` +
      `&entry.2606285=${encodeURIComponent(dietaryRestrictions)}` +
      `&entry.2090611883=${encodeURIComponent(comments)}` +
      `&entry.717549045=${encodeURIComponent(zoomEmail)}`
    );

    fetch(submissionURL)
      .then(data => { return data.json() })
      .then(res => { history.push('/rsvp-received') })
      .catch(error => { history.push('/rsvp-received') });
  }



  const formElements = willComeInPerson === null ? <></> : willComeInPerson ? (
    <>
      <p className="subtitle">{isChinese ? '期待在芝加哥見面！' : 'We can\'t wait to see you in Chicago!'}</p>

      <Form.Group>
        <InputGroup className='mx-auto' style={{maxWidth: '20rem'}}>
          <InputGroup.Text>{isChinese ? '當天參加人數' : 'Total number of attendees'}</InputGroup.Text>
          <Form.Control type="number" placeholder={1} onChange={e => {
            setNumberOfAttendees(e.target.value);
          }} />
        </InputGroup>
      </Form.Group>

      <Form.Group>
        <Form.Label>
          {isChinese ? '葷食/素食/食物過敏情形需求：' : `
            We'll have a buffet-style dinner with Chicago foods that have been meaningful to us and our relationship.
            Any dietary restrictions in your party we should know about?
          `}
        </Form.Label>
        <Form.Control as="textarea" onChange={e => setDietaryRestrictions(e.target.value)} />
      </Form.Group>

      <Form.Group>
        <Form.Label>
          {isChinese ? '其它/問題/婚禮的祝福：' : 'Any other questions or comments for us?'}
        </Form.Label>
        <Form.Control as="textarea" onChange={e => setComments(e.target.value)} />
      </Form.Group>

      <Button
        type="submit"
        variant="outline-success"
      >
        Submit RSVP
      </Button>
    </>
  ) : (
    <>
      <p className="subtitle">{isChinese ? '期待在 Zoom 上見面！' : 'We\'ll miss you on the dance floor, but we\'ll be celebrating remotely too!'}</p>

      <Form.Group>
        <Form.Label>
          {isChinese ? <>請留下您的電郵，我們會將 Zoom 資料傳給您：</> : (
            <>
              We'll plan to stream our ceremony and include hybrid activities for guests who cannot attend in-person but still want to enjoy the day with us.
              <br />
              If you'd like to receive a Zoom link, enter your email here:
            </>
          )}
        </Form.Label>
        <Form.Control 
          type="email"
          placeholder="name@example.com"
          className="mx-auto"
          style={{maxWidth:"30rem"}}
          onChange={e => setZoomEmail(e.target.value)}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>
          {isChinese ? '其它/問題/婚禮的祝福：' : 'Any other questions or comments for us?'}
        </Form.Label>
        <Form.Control as="textarea" onChange={e => setComments(e.target.value)} />
      </Form.Group>

      <Button
        variant="outline-info"
        type="submit"
      >
        Submit RSVP
      </Button>
    </>
  );





  return (
    
    <div>
      <h1>RSVP</h1>
      {isChinese ? (
        <p class="subtitle mx-auto" style={{maxWidth: '600px'}}>
          我們將於今年6月11日在芝加哥舉行婚禮，也會同步將婚禮的實況連結到Zoom ，所以無法親自前來參加的親友也能遠距觀看及共享我們的喜悅！
        </p>
      ) : (
        <p class="subtitle">
          Our wedding celebration will be held on June 11, 2022, at <a href="https://www.thehiveonhubbard.com/" target="_blank" rel="noreferrer">The Hive on Hubbard</a> in Chicago.
          <br />
          In addition to being in-person, our wedding ceremony will also be streamed live on Zoom.
        </p>
      )}

      <Form
        className="text-center mb-5 mx-auto"
        style={{maxWidth: "40rem"}}
        onSubmit={e => {
          e.preventDefault();
          submitForm({
            name: name + (willComeInPerson ? " (IN PERSON)" : " (NOT COMING)"),
            numberOfAttendees,
            dietaryRestrictions,
            comments,
            zoomEmail
          });
        }}
      >
        <InputGroup className="mx-auto my-4" style={{maxWidth: '20rem'}}>
          <InputGroup.Text>{isChinese ? '貴賓姓名' : 'Name'}</InputGroup.Text>
          <Form.Control type="text" placeholder={placeholderName} onChange={e => {
            setName(e.target.value);
          }} />
        </InputGroup>

        <h4>
          {isChinese ? '我打算。。。' : 'Will we see you there in Chicago?'}
        </h4>
        <ButtonGroup>
          <Button
            className="m-1"
            variant={(willComeInPerson ? "" : "outline-") + "success"}
            onClick={() => {
              setWillComeInPerson(true);
            }}
          >
            {isChinese ? '現場參加婚禮' : 'I\'ll be there in person!'}
          </Button>
          <Button
            className="m-1"
            variant={(willComeInPerson !== null && !willComeInPerson ? "" : "outline-") + "info"}
            onClick={() => {
              setWillComeInPerson(false);
            }}
          >
            {isChinese ? '通過 Zoom 加入' : 'I\'ll be online, perhaps!'}
          </Button>
        </ButtonGroup>

        {formElements}
      </Form>

      
    </div>
  );

}
