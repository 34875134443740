import React, { useState, useEffect } from 'react';


export default function Cue({
  danceMove,   // type DanceMove
  intendedCollisionTimestamp,   // Date object when the cue should reach the player's origin point
  speed,      // % of timeline per second
  fixedX = null,
  fixedY = null,
  scale = 1,
  showBackground = false,
  ...props
}) {

  // Do math to figure out x-position of the cue within the timeline, every 40 ms
  const [timelinePctX, setTimelinePctX] = useState();
  useEffect(() => {
    if (!fixedX || !fixedY) {
      const interval = setInterval(() => {
        setTimelinePctX(10 + speed * (intendedCollisionTimestamp - Date.now()) / 1000);
      }, 40);
      return () => clearInterval(interval);
    }
  }, [intendedCollisionTimestamp, speed, fixedX, fixedY]);

  let opacity = 1;
  if (!fixedX || !fixedY) {

    // Return an empty/invisible element if it's not worth rendering
    if (timelinePctX < -10 || timelinePctX > 110) return <></>;

    // Decide on opacity for fade in and out of timeline
    if (timelinePctX > 75) {
      opacity = 1 - (timelinePctX - 75) / 25;
    } else if (timelinePctX < 5) {
      opacity = (timelinePctX + 5) / 10;
    }
    if (opacity < 0) opacity = 0;
  }


  return (
    <div
      style={{
        position: "absolute",
        top: fixedY ? fixedY : "3px",
        left: fixedX ? fixedX : `${timelinePctX}%`,
        opacity: `${opacity}`,
        transform: `scale(${scale})`,
      }}
      {...props}
    >
      {showBackground ? (
        <div
          style={{
            position: "absolute",
            left: "50%",
            transform: "translate(-50%, -18%)",
            top: "-20%",
            width: "100px",
            height: "100px",
            backgroundColor: "rgb(242,205,124,0.85)",
            borderRadius: "14px",
          }}
        />
      ) : <></>}
      <img
        src={danceMove.heartSrc}
        alt="Incoming Move"
        style={{
          color: "blue",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          maxHeight: "1000px",
          width: "25px",
        }}
      />
      <img
        src={danceMove.cueSrc}
        alt={danceMove.moveType}
        style={{
          position: "absolute",
          top: "13px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "100px",
          maxHeight: "1000px",
        }}
      />
    </div>
  );
}