import React from 'react';


export default function ReceivedRSVP() {
  return (
    <div className="text-center">
      <h2>Thank you for your RSVP!</h2>

      <p className="subtitle">Please let us know if you have any additional thoughts or questions!</p>

      <img src="/k-on-phone.jpg" alt="RSVPed" style={{margin: "20px", maxWidth: "250px"}} />
    </div>
  );
}