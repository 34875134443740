import React, { useRef, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';

const DEFAULT_FORM_RESPONSES = {
  q1: {isCorrect: false, points: 0},
  q2a: {isCorrect: false, points: 0},
  q2b: {isCorrect: true, points: 0},
  q2c: {isCorrect: true, points: 0},
  q2d: {isCorrect: true, points: 0},
  q2e: {isCorrect: false, points: 0},
  q3: {isCorrect: false, points: 0},
  q4: {isCorrect: false, points: 0},
  q4Bonus: {isCorrect: false, points: 0},
  q5a: {isCorrect: false, points: 0},
  q5b: {isCorrect: false, points: 0},
  q5c: {isCorrect: false, points: 0},
  q5d: {isCorrect: false, points: 0},
  q5e: {isCorrect: false, points: 0},
  q5f: {isCorrect: false, points: 0},
  q6: {isCorrect: false, points: 0},
  q7: {isCorrect: false, points: 0},
  q8: {isCorrect: false, points: 0},
  q9a: {isCorrect: false, points: 0},
  q9b: {isCorrect: false, points: 0},
  q9c: {isCorrect: false, points: 0},
  q9d: {isCorrect: false, points: 0},
  q9e: {isCorrect: false, points: 0},
  q9f: {isCorrect: false, points: 0},
  q9g: {isCorrect: false, points: 0},
};

export default function Trivia() {

  const [tableNumber, setTableNumber] = useState();
  const [isTableNumberConfirmed, setIsTableNumberConfirmed] = useState(false);
  const [answersSubmitted, setAnswersSubmitted] = useState(false);

  const formResponses = useRef(DEFAULT_FORM_RESPONSES);
  // formResponses.current is an object with the question id (q1, q2, etc) mapped to an object of the structure
  // {isCorrect: Boolean, points: Number}

  const [q6aValue, setQ6aValue] = useState();
  const [q6bValue, setQ6bValue] = useState();
  const [q6cValue, setQ6cValue] = useState();
  const [q6dValue, setQ6dValue] = useState();
  const [q6eValue, setQ6eValue] = useState();

  const [tiebreakerValue, setTiebreakerValue] = useState();

  const [q1Confirmed, setQ1Confirmed] = useState(false);
  const [q2Confirmed, setQ2Confirmed] = useState(false);
  const [q3Confirmed, setQ3Confirmed] = useState(false);
  const [q4Confirmed, setQ4Confirmed] = useState(false);
  const [q5Confirmed, setQ5Confirmed] = useState(false);
  const [q6Confirmed, setQ6Confirmed] = useState(false);
  const [q7Confirmed, setQ7Confirmed] = useState(false);
  const [q8Confirmed, setQ8Confirmed] = useState(false);
  const [q9Confirmed, setQ9Confirmed] = useState(false);

  if (!isTableNumberConfirmed) {
    return <div className='text-center'>
      <h2>Trivia Time!</h2>
      <p className='subtitle'>As a table, enter your trivia answers here for a chance to win a prize!</p>
      <InputGroup className='mx-auto mt-5' style={{maxWidth: '13rem'}}>
        <InputGroup.Text>Table Number:</InputGroup.Text>
        <Form.Control 
          type='number'
          onChange={e => {
            setTableNumber(e.target.value);
          }}
        />
      </InputGroup>
      <Button
        className='mt-3'
        variant="success"
        disabled={!tableNumber}
        onClick={() => {
          setIsTableNumberConfirmed(true);
        }}
      >
        Go!
      </Button>
    </div>;
  }
  
  return (
    <div className='text-center'>
      <Form style={{maxWidth: "36rem", marginLeft: "auto", marginRight: "auto"}}>
        <h3>Trivia answers for Table {tableNumber}</h3>
        <p className='subtitle'>
          Check each answer along the way to see if your table got it right!
          Your final score won't be submitted until you click the last button at the very bottom.
        </p>

        <Form.Group className='mt-4'>
          <Form.Label>
            <h6>1. Which of the following names refers to only one guest today?</h6>
            <small>1 point</small>
          </Form.Label>
          <Form.Check type='radio' label='a) Roger' name='q1' id='q1a' onClick={() => formResponses.current.q1 = {isCorrect: false, points: 0}} />
          <Form.Check type='radio' label='b) Alex' name='q1' id='q1b' onClick={() => formResponses.current.q1 = {isCorrect: false, points: 0}} />
          <Form.Check type='radio' label='c) Emily' name='q1' id='q1c' onClick={() => formResponses.current.q1 = {isCorrect: true, points: 1}} />
          <Form.Check type='radio' label='d) David' name='q1' id='q1d' onClick={() => formResponses.current.q1 = {isCorrect: false, points: 0}} />
          <Form.Check type='radio' label='e) Ben' name='q1' id='q1e' onClick={() => formResponses.current.q1 = {isCorrect: false, points: 0}} />

          {q1Confirmed ? (
            formResponses.current.q1.isCorrect ? (
              <div className='bg-success'>Correct! +1 point</div>
            ) : (
              <div className='bg-warning'>Wrong! There is only one Emily here today!</div>
            )
          ) : (
            <Button
              variant='info'
              onClick={() => setQ1Confirmed(true)}
            >
              Check Answer
            </Button>
          )} 
        </Form.Group>

        <Form.Group>
          <Form.Label>
            <h6>2. Which shows are Kevin and May eagerly awaiting each week?</h6>
            <small>Multiple answers! +1 for selecting each correct show; -1 for each wrong selection</small>
          </Form.Label>
          <Form.Check label='Kenobi' name='q2' id='q2a' onChange={e => {
            if (e.target.checked) {
              formResponses.current.q2a = {isCorrect: true, points: 1};
            } else {
              formResponses.current.q2a = {isCorrect: false, points: 0};
            }
          }} />
          <Form.Check label='Superman &amp; Lois' name='q2' id='q2b' onChange={e => {
            if (e.target.checked) {
              formResponses.current.q2b = {isCorrect: false, points: -1};
            } else {
              formResponses.current.q2b = {isCorrect: true, points: 0};
            }
          }}/>
          <Form.Check label='Star Trek: Strange New Worlds' name='q2' id='q2c' onChange={e => {
            if (e.target.checked) {
              formResponses.current.q2c = {isCorrect: false, points: -1};
            } else {
              formResponses.current.q2c = {isCorrect: true, points: 0};
            }
          }} />
          <Form.Check label='Kitchen Nightmares' name='q2' id='q2d' onChange={e => {
            if (e.target.checked) {
              formResponses.current.q2d = {isCorrect: false, points: -1};
            } else {
              formResponses.current.q2d = {isCorrect: true, points: 0};
            }
          }} />
          <Form.Check label='The Kardashians' name='q2' id='q2e' onChange = {e=> {
            if (e.target.checked) {
              formResponses.current.q2e = {isCorrect: true, points: 1};
            } else {
              formResponses.current.q2e = {isCorrect: false, points: 0};
            }
          }} />

          {q2Confirmed ? (
            formResponses.current.q2a.isCorrect &&
                formResponses.current.q2b.isCorrect &&
                formResponses.current.q2c.isCorrect &&
                formResponses.current.q2d.isCorrect &&
                formResponses.current.q2e.isCorrect ? (
                  <div className='bg-success'>Correct! +2 points</div>
                ) : (
                  <div className='bg-warning'>Correct answer: Kenobi and The Kardashians!&nbsp;
                  {formResponses.current.q2a.points + 
                    formResponses.current.q2b.points +
                    formResponses.current.q2c.points +
                    formResponses.current.q2d.points +
                    formResponses.current.q2e.points} points</div>
                )
          ) : (
            <Button
              variant='info'
              onClick={() => setQ2Confirmed(true)}
            >
              Check Answer
            </Button>
          )}
        </Form.Group>

        <Form.Group>
          <Form.Label>
            <h6>3. Where are May and Kevin going for their honeymoon?</h6>
            <small>1 point</small>
          </Form.Label>
          <Form.Check type='radio' label='a) Alaska' name='q3' id='q3a' onClick={e => {
            formResponses.current.q3 = {isCorrect: false, points: 0};
          }} />
          <Form.Check type='radio' label='b) Seoul' name='q3' id='q3b' onClick={e => {
            formResponses.current.q3 = {isCorrect: false, points: 0};
          }} />
          <Form.Check type='radio' label='c) St. Croix' name='q3' id='q3c' onClick={e => {
            formResponses.current.q3 = {isCorrect: false, points: 0};
          }} />
          <Form.Check type='radio' label='d) Nashville' name='q3' id='q3d' onClick={e => {
            formResponses.current.q3 = {isCorrect: true, points: 1};
          }} />
          <Form.Check type='radio' label='e) Hawaii' name='q3' id='q3e' onClick={e => {
            formResponses.current.q3 = {isCorrect: false, points: 0};
          }} />

          {q3Confirmed ? (
            formResponses.current.q3.isCorrect ? (
              <div className='bg-success'>Correct! +1 point</div>
            ) : (
              <div className='bg-warning'>Wrong! They are going to Nashville to see the Country Music Hall of Fame :)</div>
            )
          ) : (
            <Button
              variant='info'
              onClick={() => setQ3Confirmed(true)}
            >Check Answer</Button>
          )}
        </Form.Group>

        <Form.Group>
          <Form.Label>
            <div>4. Which K-Drama did May's aisle song come from?</div>
            <small>1 point</small>
          </Form.Label>
          <Form.Check type='radio' label='a) Crash Landing on You' name='q4' id='q4a' onClick={e => {
            formResponses.current.q4 = {isCorrect: true, points: 1};
          }} />
          <Form.Check type='radio' label='b) Guardian' name='q4' id='q4b' onClick={e => {
            formResponses.current.q4 = {isCorrect: false, points: 0};
          }} />
          <Form.Check type='radio' label='c) Boys Over Flowers' name='q4' id='q4c' onClick={e => {
            formResponses.current.q4 = {isCorrect: false, points: 0};
          }} />
          <Form.Check type='radio' label='d) Squid Game' name='q4' id='q4d' onClick={e => {
            formResponses.current.q4 = {isCorrect: false, points: 0};
          }} />
          <Form.Check type='radio' label='e) Legend of the Blue Sea' name='q4' id='q4e' onClick={e => {
            formResponses.current.q4 = {isCorrect: false, points: 0};
          }} />

          <Form.Label>Bonus point: What is the title of the song?</Form.Label>
          <Form.Control id='q4-bonus' onChange={e => {
            if (e.target.value.toLowerCase().trim() === 'flower') {
              formResponses.current.q4Bonus = {isCorrect: true, points: 1};
            } else {
              formResponses.current.q4Bonus = {isCorrect: false, points: 0};
            }
          }} />

          {q4Confirmed ? (
            formResponses.current.q4.isCorrect ? (
              formResponses.current.q4Bonus.isCorrect ? (
                <div className='bg-success'>Correct to both! +2 points!!</div>
              ) : (
                <div className='bg-success'>Correct - The K-Drama was Crash Landing on You, but the song is named Flower by Yoon Mirae.</div>
              )
            ) : (
              <div className='bg-warning'>Wrong! It is from Crash Landing on You - which May and Kevin would both highly recommend!</div>
            )
          ) : (
            <Button
              variant='info'
              onClick={() => setQ4Confirmed(true)}
            >Check Answer</Button>
          )} 
        </Form.Group>

        <Form.Group>
          <Form.Label>
            <h6>5. Which cities are represented by our wedding guests?</h6>
            <small>Multiple answers! +1 for selecting each correct city; -1 for each wrong selection</small>
          </Form.Label>
          <Form.Check label='Montreal' name='q5' id='q5a' onClick={e => {
            formResponses.current.q5a = {isCorrect: e.target.checked, points: e.target.checked ? 1 : 0}
          }} />
          <Form.Check label='Zurich' name='q5' id='q5b' onClick={e => {
            formResponses.current.q5b = {isCorrect: e.target.checked, points: e.target.checked ? 1 : 0}
          }} />
          <Form.Check label='New York' name='q5' id='q5c' onClick={e => {
            formResponses.current.q5c = {isCorrect: e.target.checked, points: e.target.checked ? 1 : 0}
          }} />
          <Form.Check label='Boston' name='q5' id='q5d' onClick={e => {
            formResponses.current.q5d = {isCorrect: e.target.checked, points: e.target.checked ? 1 : 0}
          }} />
          <Form.Check label='San Francisco' name='q5' id='q5e' onClick = {e => {
            formResponses.current.q5e = {isCorrect: e.target.checked, points: e.target.checked ? 1 : 0}
          }} />
          <Form.Check label='Crane Hill, Alabama' name='q5' id='q5f' onClick ={e=> {
            formResponses.current.q5f = {isCorrect: e.target.checked, points: e.target.checked ? 1 : 0}
          }}/>

          {q5Confirmed ? (
            formResponses.current.q5a.isCorrect &&
              formResponses.current.q5b.isCorrect &&
              formResponses.current.q5c.isCorrect &&
              formResponses.current.q5d.isCorrect &&
              formResponses.current.q5e.isCorrect &&
              formResponses.current.q5f.isCorrect ? (
                <div className='bg-success'>Correct! +6 points</div>
              ) : (
                <div className='bg-warning'>We actually have guests from every city here! +{
                  formResponses.current.q5a.points +
                  formResponses.current.q5b.points +
                  formResponses.current.q5c.points +
                  formResponses.current.q5d.points +
                  formResponses.current.q5e.points +
                  formResponses.current.q5f.points
                }/6 points</div>
              )
          ) : (
            <Button
              variant='info'
              onClick={() => setQ5Confirmed(true)}
            >Check Answer</Button>
          )}
        </Form.Group>

        <Form.Group>
          <Form.Label>
            <h6>6. Rank the following date ideas in order (1-5) by May and Kevin's preferences!</h6>
            <small>5 pts. available, scaled by the number of pairwise comparisons gotten correct</small>
          </Form.Label>
          <div className='mb-1'>
            <Form.Control className='d-inline mr-1' type='number' id='q6a' style={{width: '4rem'}} onChange={e => setQ6aValue(e.target.value)}/>
            <span className='d-inline'>Ordering in Indian food over&nbsp;<em>Star Wars: The Clone Wars</em></span>
          </div>
          <div className='mb-1'>
            <Form.Control className='d-inline mr-1' type='number' id='q6b' style={{width: '4rem'}} onChange={e => setQ6bValue(e.target.value)}/>
            <span className='d-inline'>Eating at Alinea (or another fine dining restaurant)</span>
          </div>
          <div className='mb-1'>
            <Form.Control className='d-inline mr-1' type='number' id='q6c' style={{width: '4rem'}} onChange={e => setQ6cValue(e.target.value)}/>
            <span className='d-inline'>Attending a country music concert</span>
          </div>
          <div className='mb-1'>
            <Form.Control className='d-inline mr-1' type='number' id='q6d' style={{width: '4rem'}} onChange={e => setQ6dValue(e.target.value)}/>
            <span className='d-inline'>Picknicking after Lindy in the Park</span>
          </div>
          <div className='mb-1'>
            <Form.Control className='d-inline mr-1' type='number' id='q6e' style={{width: '4rem'}} onChange={e => setQ6eValue(e.target.value)}/>
            <span className='d-inline'>Bungee jumping</span>
          </div>

          {q6Confirmed ? (
            <div className={formResponses.current.q6.isCorrect? 'bg-success' : 'bg-warning'}>Indian/Star Wars &gt; Country music concert &gt; Picnic/Lindy &gt; Alinea &gt; Bungee jumping. +{
              formResponses.current.q6.points
            }/5 points!</div>
          ) : (
            <Button
              variant='info'
              onClick={() => {
                // Evaluate scaled # of points
                const givenResponses = [q6aValue, q6cValue, q6dValue, q6bValue, q6eValue];
                // Calculate number of cases that are correctly pairwise ordered
                var totalComparisons = 0;
                var correctComparisons = 0;
                for (let i = 0; i < givenResponses.length; ++i) {
                  for (let j = i + 1; j < givenResponses.length; ++j) {
                    totalComparisons += 1;
                    if (givenResponses[i] < givenResponses[j]) {
                      correctComparisons += 1;
                    }
                  }
                }
                const points = correctComparisons / totalComparisons * 5;
                formResponses.current.q6 = {isCorrect: points > 4, points: points};
                setQ6Confirmed(true);
              }}
            >Check Answer</Button>
          )}
        </Form.Group>

        <Form.Group>
          <Form.Label>
            <h6>7. Where was May and Kevin's first date?</h6>
            <small>2 points</small>
          </Form.Label>
          <Form.Check type='radio' label='a) Promontory Point by Lake Michigan' name='q7' id='q7a' onClick={e=> {
            formResponses.current.q7 = {isCorrect: true, points: 2};
          }} />
          <Form.Check type='radio' label='b) Giordanos on 53rd street' name='q7' id='q7b' onClick={e => {
            formResponses.current.q7 = {isCorrect: false, points: 0};
          }} />
          <Form.Check type='radio' label='c) Doc Films at Ida Noyes' name='q7' id='q7c' onClick={e => {
            formResponses.current.q7 = {isCorrect: false, points: 0};
          }} />
          <Form.Check type='radio' label='d) Harper Memorial Library (to study)' name='q7' id='q7d' onClick={e => {
            formResponses.current.q7 = {isCorrect: false, points: 0};
          }} />
          <Form.Check type='radio' label='e) The Art Institute of Chicago' name='q7' id='q7e' onClick={e=> {
            formResponses.current.q7 = {isCorrect: false, points: 0};
          }}/>

          {q7Confirmed ? (
            formResponses.current.q7.isCorrect ? (
              <div className='bg-success'>Correct! +2 points</div>
            ) : (
              <div className='bg-warning'>Wrong! Our first date was at the Point after a swing dance performance thing!</div>
            )
          ) : (
            <Button
              variant='info'
              onClick={() => setQ7Confirmed(true)}
            >Check Answer</Button>
          )}
        </Form.Group>

        <Form.Group>
          <Form.Label>
            <h6>8. What is Kevin's Myers-Briggs personality type?</h6>
            <small>1 point</small>
          </Form.Label>
          <Form.Check type='radio' label='a) INTJ (The Architect)' name='q8' id='q8a' onClick={e => {
            formResponses.current.q8 = {isCorrect: false, points: 0};
          }} />
          <Form.Check type='radio' label='b) INTP (The Logician)' name='q8' id='q8b' onClick={e => {
            formResponses.current.q8 = {isCorrect: false, points: 0};
          }} />
          <Form.Check type='radio' label='c) ISTP (The Virtuoso)' name='q8' id='q8c' onClick={e => {
            formResponses.current.q8 = {isCorrect: false, points: 0};
          }} />
          <Form.Check type='radio' label='d) ENFJ (The Protagonist)' name='q8' id='q8d' onClick={e => {
            formResponses.current.q8 = {isCorrect: true, points: 1};
          }}/>
          <Form.Check type='radio' label='e) ENTP (The Debater)' name='q8' id='q8e' onClick={e => {
            formResponses.current.q8 = {isCorrect: false, points: 0};
          }}/>

          {q8Confirmed ? (
            formResponses.current.q8.isCorrect ? (
              <div className='bg-success'>Correct! +1 point</div>
            ) : (
              <div className='bg-warning'>Wrong! Kevin's personality type is apparently ENFJ...</div>
            )
          ) : (
            <Button
              variant='info'
              onClick={() => setQ8Confirmed(true)}
            >Check Answer</Button>
          )}
        </Form.Group>

        <Form.Group>
          <Form.Label>
            <h6>9. Between Kevin and May, who's better at each of these?</h6>
            <small>7 points</small>
          </Form.Label>
          <Row>
            <Col>Solving Rubik's cubes</Col>
            <Col>
              <Form.Check className='d-inline mr-1' type='radio' label='K' name='q9a' id='q9aK' onClick={()=>formResponses.current.q9a = {isCorrect: false, points: 0}}/>
              <Form.Check className='d-inline' type='radio' label='M' name='q9a' id='q9aM' onClick={()=>formResponses.current.q9a = {isCorrect: true, points: 1}}/>
              {q9Confirmed && (formResponses.current.q9a.isCorrect ? <div className='bg-success'>Correct! +1</div> : <div className='bg-warning'>Wrong!</div>)}
            </Col>
          </Row>
          <Row>
            <Col>Hula hooping</Col>
            <Col>
              <Form.Check className='d-inline mr-1' type='radio' label='K' name='q9b' id='q9bK' onClick={()=>formResponses.current.q9b = {isCorrect: false, points: 0}}/>
              <Form.Check className='d-inline' type='radio' label='M' name='q9b' id='q9bM' onClick={()=>formResponses.current.q9b = {isCorrect: true, points: 1}}/>
              {q9Confirmed && (formResponses.current.q9b.isCorrect ? <div className='bg-success'>Correct! +1</div> : <div className='bg-warning'>Wrong!</div>)}
            </Col>
          </Row>
          <Row>
            <Col>Waking up in the morning</Col>
            <Col>
              <Form.Check className='d-inline mr-1' type='radio' label='K' name='q9c' id='q9cK' onClick={()=>formResponses.current.q9c = {isCorrect: true, points: 1}}/>
              <Form.Check className='d-inline' type='radio' label='M' name='q9c' id='q9cM' onClick={()=>formResponses.current.q9c = {isCorrect: false, points: 0}}/>
              {q9Confirmed && (formResponses.current.q9c.isCorrect ? <div className='bg-success'>Correct! +1</div> : <div className='bg-warning'>Wrong!</div>)}
            </Col>
          </Row>
          <Row>
            <Col>Wii Tennis</Col>
            <Col>
              <Form.Check className='d-inline mr-1' type='radio' label='K' name='q9d' id='q9dK' onClick={()=>formResponses.current.q9d = {isCorrect: true, points: 1}}/>
              <Form.Check className='d-inline' type='radio' label='M' name='q9d' id='q9dM' onClick={()=>formResponses.current.q9d = {isCorrect: false, points: 0}} />
              {q9Confirmed && (formResponses.current.q9d.isCorrect ? <div className='bg-success'>Correct! +1</div> : <div className='bg-warning'>Wrong!</div>)}
            </Col>
          </Row>
          <Row>
            <Col>Playing music by ear</Col>
            <Col>
              <Form.Check className='d-inline mr-1' type='radio' label='K' name='q9e' id='q9eK' onClick={()=>formResponses.current.q9e = {isCorrect: false, points: 0}}/>
              <Form.Check className='d-inline' type='radio' label='M' name='q9e' id='q9eM' onClick={()=>formResponses.current.q9e = {isCorrect: true, points: 1}}/>
              {q9Confirmed && (formResponses.current.q9e.isCorrect ? <div className='bg-success'>Correct! +1</div> : <div className='bg-warning'>Wrong!</div>)}
            </Col>
          </Row>
          <Row>
            <Col>Making rice</Col>
            <Col>
              <Form.Check className='d-inline mr-1' type='radio' label='K' name='q9f' id='q9fK' onClick={()=>formResponses.current.q9f = {isCorrect: true, points: 1}}/>
              <Form.Check className='d-inline' type='radio' label='M' name='q9f' id='q9fM' onClick={()=>formResponses.current.q9f = {isCorrect: false, points: 0}} />
              {q9Confirmed && (formResponses.current.q9f.isCorrect ? <div className='bg-success'>Correct! +1</div> : <div className='bg-warning'>Wrong!</div>)}
            </Col>
          </Row>
          <Row>
            <Col>Naming periodic table elements</Col>
            <Col>
              <Form.Check className='d-inline mr-1' type='radio' label='K' name='q9g' id='q9gK' onClick={()=>formResponses.current.q9g = {isCorrect: true, points: 1}}/>
              <Form.Check className='d-inline' type='radio' label='M' name='q9g' id='q9gM' onClick={()=>formResponses.current.q9g = {isCorrect: false, points: 0}}/>
              {q9Confirmed && (formResponses.current.q9g.isCorrect ? <div className='bg-success'>Correct! +1</div> : <div className='bg-warning'>Wrong!</div>)}
            </Col>
          </Row>

          {q9Confirmed ? (
            null
          ) : (
            <Button
              variant='info'
              onClick={() => setQ9Confirmed(true)}
            >
              Check Answers
            </Button>
          )}
        </Form.Group>

        <Form.Group>
          <Form.Label>
            <h6>Tiebreaker: Without checking, how many Twitter followers does May have?</h6>
            <small>Closest answer will win any ties!</small>
          </Form.Label>
          <Form.Control type='number' id='tiebreaker' onChange={e => {
            setTiebreakerValue(e.target.value);
          }} />
        </Form.Group>

        {answersSubmitted ? (
          <div className='bg-success'><strong>Submitted! You scored {Object.values(formResponses.current).map(val => val.points).reduce((a, b) => a + b, 0)} points!</strong></div>
        ) : (
          <Button
            disabled={!(q1Confirmed && q2Confirmed && q3Confirmed && q4Confirmed && q5Confirmed && q6Confirmed && q7Confirmed && q8Confirmed && q9Confirmed)}
            onClick={() => {
              const finalScore = Object.values(formResponses.current).map(val => val.points).reduce((a, b) => a + b, 0);
              const url = `https://docs.google.com/forms/d/e/1FAIpQLSd_1Nx5hZn3Z0QoGxH-QmBgvDsuMNe16Mf2pQtZKSoi-PmwQA/formResponse?entry.1283943192=${encodeURIComponent(`Table ${tableNumber} with a score of ${finalScore}`)}&entry.161339573=${encodeURIComponent(`Guess: ${tiebreakerValue} (${Math.abs(parseInt(tiebreakerValue) - 1130)} away from actual value)`)}`;
              fetch(url)
                .then(data => { return data.json() })
                .then(res => { setAnswersSubmitted(true) })
                .catch(error => { setAnswersSubmitted(true) });
            }}
          >Submit our answers!</Button>
        )}

      </Form>
    </div>
  );

}