import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import JustForFun from "./containers/JustForFun";
import NotFound from './containers/NotFound';
import RSVP from "./containers/RSVP";
import ReceivedRSVP from "./containers/ReceivedRSVP";
import OurStory from "./containers/OurStory";
import Chicago from "./containers/Chicago";
import Lindy from "./containers/lindy/Lindy";
import FAQ from "./containers/FAQ";
import Trivia from "./containers/Trivia";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/justforfun">
        <JustForFun />
      </Route>
      <Route exact path="/RSVP">
        <RSVP />
      </Route>
      <Route exact path="/RSVP-received">
        <ReceivedRSVP />
      </Route>
      <Route exact path="/chicago">
        <Chicago />
      </Route>
      <Route exact path="/faq">
        <FAQ />
      </Route>
      <Route exact path="/story">
        <OurStory />
      </Route>
      <Route exact path="/lindy">
        <Lindy />
      </Route>
      <Route exact path="/trivia">
        <Trivia />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
