import React from 'react';
import "./Home.css";
import { Container, Row, Col } from "react-bootstrap";
import { useLanguage } from "../App";




export default function Home() {

  const { isChinese } = useLanguage();

  const agenda = (
    <div className="mx-auto mt-4" style={{
      padding: "0 20%",
      fontSize: "22px",
    }}>
      <div className="d-flex mx-5">
        <span>3:30</span>
        <span className="ml-auto">🚪 {isChinese ? "賓客入座" : "doors open"}</span>
      </div>
      <hr />
      <div className="d-flex mx-5">
        <span>4:00</span>
        <span className="ml-auto">💒 {isChinese ? '結婚典禮' : 'ceremony & livestream'}</span>
      </div>
      <hr />
      <div className="d-flex mx-5">
        <span>4:30</span>
        <span className="ml-auto">🥂 {isChinese ? '雞尾酒時間' : 'social hour'}</span>
      </div>
      <hr />
      <div className="d-flex mx-5">
        <span>5:30</span>
        <span className="ml-auto">🍽️ {isChinese ? '晚宴' : 'dinner'}</span>
      </div>
      <hr />
      <div className="d-flex mx-5">
        <span>7:30</span>
        <span className="ml-auto">💃 {isChinese ? '跳舞、遊戲、等等' : 'dancing & games'}</span>
      </div>
      <hr />
      <div className="d-flex mx-5">
        <span>10:00</span>
        <span className="ml-auto">✨ {isChinese ? '喜宴結束' : 'end'}</span>
      </div>
    </div>
  )



  return (
    <div className="Home">
      <div className="lander">
        <h1 className="mb-1"> 
          {isChinese ? '黃鴻霙' : 'May'} &amp; {isChinese ? '松涛' : 'Kevin'}
        </h1>
        <p className="subtitle">
          {isChinese ? '2022 年 6 月 11 日' : 'June 11th, 2022'} · {isChinese ? '芝加哥' : 'Chicago, IL'}
        </p>

        <Container>
          <Row>
            <Col xs={4}>
              <img
                className="w-100"
                src="cover1.jpg"
                alt="cover1"
              />
            </Col>
            <Col xs={4}>
              <img
                className="w-100"
                src="cover2.jpg" 
                alt="cover2"
              />
            </Col>
            <Col xs={4}>
              <img
                className="w-100"
                src="cover3.jpg" 
                alt="cover3"
              />
            </Col>
          </Row>
        </Container>

        <div className="mx-auto mt-4">
          <h2>{isChinese ? '婚禮流程' : 'Our celebration day'}</h2>
          <p className="subtitle">Schedule is in Central Time. More details to follow!</p>
          {agenda}
        </div>

        <div className="mx-auto mt-5 lg">
          <em>
            We are traveling for our wedding and would prefer that our guests don't bring any gifts.
            <br />
            Your presence and well wishes are more than enough for us!
          </em>
        </div>

        <div className="mx-auto mt-4 text-muted"><small>Thanks to David Tran for these photos!</small></div>

      </div>
    </div>
  )
}
