// Import spritesheets
import basic from './spritesheets/basic.png';
import followTurn from './spritesheets/followTurn.png';
import minnieDip from './spritesheets/minnieDip.png';
import swingout from './spritesheets/swingout.png';
import flourish from './spritesheets/flourish.png';

// Import cues
import cueBasic from './cues/cue basic.png';
import cueFollowTurn from './cues/cue followTurn.png';
import cueMinnieDip from './cues/cue minnieDip.png';
import cueSwingout from './cues/cue swingout.png';
import cueFlourish from './cues/cue flourish.png';

// Import cue hearts
import heartBasic from './cues/hearts/heart basic.png';
import heartFollowTurn from './cues/hearts/heart followTurn.png';
import heartMinnieDip from './cues/hearts/heart minnieDip.png';
import heartSwingout from './cues/hearts/heart swingout.png';
import heartFlourish from './cues/hearts/heart flourish.png';

export const MoveTypes = {
  BASIC: 'basic',
  FOLLOW_TURN: 'followTurn',
  SWINGOUT: 'swingout',
  MINNIE_DIP: 'minnieDip',
  FLOURISH: 'flourish',
};


export default class DanceMove {
  constructor(moveType) {
    this.moveType = moveType;

    switch (moveType) {
    case MoveTypes.BASIC:
      this.spritesheetSrc = basic;
      this.cueSrc = cueBasic;
      this.heartSrc = heartBasic;
      break;
    case MoveTypes.FOLLOW_TURN:
      this.spritesheetSrc = followTurn;
      this.cueSrc = cueFollowTurn;
      this.heartSrc = heartFollowTurn;
      break;
    case MoveTypes.MINNIE_DIP:
      this.spritesheetSrc = minnieDip;
      this.cueSrc = cueMinnieDip;
      this.heartSrc = heartMinnieDip;
      break;
    case MoveTypes.SWINGOUT:
      this.spritesheetSrc = swingout;
      this.cueSrc = cueSwingout;
      this.heartSrc = heartSwingout;
      break;
    case MoveTypes.FLOURISH:
      this.spritesheetSrc = flourish;
      this.cueSrc = cueFlourish;
      this.heartSrc = heartFlourish;
      break;
    default:
      throw new Error(`${moveType} is not a recognized DanceMove`);
    }
  }


  static newDanceMove({
    not = null,
  }) {
    const mts = Object.values(MoveTypes);
    let move = mts[Math.floor(Math.random() * (mts.length-1))];  // length-1 to avoid the Flourish, which is the last one
    while (!not && move === not) {
      move = mts[Math.floor(Math.random() * (mts.length-1))];
    }
    return new DanceMove(move);
  }
}