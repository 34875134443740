import React from "react";
import { Nav, Row, Col, Tab } from "react-bootstrap";
import { useLanguage } from "../App";
import Lindy from './lindy/Lindy'

export default function JustForFun() {

  const { isChinese } = useLanguage();

  return (
    <Tab.Container defaultActiveKey="crossword">
      <Row>
        <Col sm={2}>
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="crossword">{isChinese ? '填字遊戲' : 'Crossword'}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="spotify">{isChinese ? '我們的歌' : 'Spotify'}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="lindy">{isChinese ? '一起跳舞！' : 'Let\'s Lindy!'}</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={10}>
          <Tab.Content>
            <Tab.Pane eventKey="crossword">
              <iframe
                title="May and Kevin Wedding Crossword"
                src="//amuselabs.com/pmm/crossword?id=09c95451&set=1158052475352fb529072fe3bc67f87990be16bd6a30b174f8bbcee7db9b8bef&embed=1"
                width="100%"
                height="600px"
                allowfullscreen="true"
                style={{
                  border: "0px"
                }}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="spotify">
              <iframe
                title="May and Kevin Spotify"
                src="https://open.spotify.com/embed/playlist/3MNqDkZavjhus42TGreuLV?theme=0" 
                width="100%" 
                height="380" 
                frameBorder="0" 
                allowfullscreen="" 
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              />
            </Tab.Pane>
            <Tab.Pane eventKey="lindy">
              <Lindy />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
    

    // <CardDeck className="text-center">
    //   <Card style={{
    //     maxWidth: "300px",
    //     marginBottom: "auto",
    //   }}>
    //     <Card.Header>
    //       <h5>Spotify</h5>
    //     </Card.Header>
    //     <Card.Body className="p-0">
    //       <iframe
    //         title="May and Kevin Spotify"
    //         src="https://open.spotify.com/embed/playlist/3MNqDkZavjhus42TGreuLV?theme=0" 
    //         width="100%" 
    //         height="380" 
    //         frameBorder="0" 
    //         allowfullscreen="" 
    //         allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
    //       />

    //     </Card.Body>
    //   </Card>

    //   <Card style={{
    //     maxWidth: "700px",
    //     marginBottom: "auto",
    //   }}>
    //     <Card.Header>
    //       <h5>Crossword</h5>
    //       <p class="subtitle m-0">Play more at <a href="https://www.crossworthy.net/" target="_blank" rel="noreferrer">crossworthy.net</a>!</p>
    //     </Card.Header>
    //     <Card.Body className="p-0">
    //     </Card.Body>
    //   </Card>

    // </CardDeck>
  );
}
