import React, { useRef, useEffect, useState } from 'react';
import { Button, Form, ListGroup } from 'react-bootstrap';
import DanceMove, { MoveTypes } from './DanceMove';
import Hero from './Hero';
import Timeline from './Timeline';
import './Lindy.css';

import miss from './miss.mp3';
import hit from './hit.mp3';
import Cue from './Cue';
import { useLanguage } from '../../App';


const defaultPressedKeys = {
  KeyQ: false,
  KeyW: false,
  KeyE: false,
  KeyA: false,
  KeyS: false,
  KeyD: false,
};

const audio = new Audio('/solidAsARock.mp3');
const hitSFX = new Audio(hit);
const missSFX = new Audio(miss);


const STAGE_WIDTH = 600;
const STAGE_HEIGHT = 400;

const TIMELINE_SPEED = 10;
const TOTAL_NUMBER_OF_MOVES = 100;



export default function Lindy() {

  const { isChinese } = useLanguage();

  // Whether or not game is in progress
  const [gameStartTime, setGameStartTime] = useState(null);  // null if no game in progress
  const [gameFinished, setGameFinished] = useState(false);
  const [scoreSubmitted, setScoreSubmitted] = useState(false);

  const gameTimeoutIdRef = useRef(null);  // used to clearTimeout if game is reset midway

  const [displayName, setDisplayName] = useState('');

  // # of moves they've nailed
  const [score, setScore] = useState(0);


  // Planned moves & collision times for the Timeline
  const plannedMoves = useRef([]);

  // Current move being performed by couple
  const [currentMove, setCurrentMove] = useState(new DanceMove('basic'));  // of type DanceMove
  const [currentMoveStartTime, setCurrentMoveStartTime] = useState(null);


  // Add states for currently pressed keys, which decide current move
  const pressedKeys = useRef(defaultPressedKeys);

  // Sync ref with gameStartTime and gameFinished states to be able to access it asynchronously e.g. with setTimeout
  const gameStartTimeRef = useRef(gameStartTime);
  gameStartTimeRef.current = gameStartTime;
  const gameFinishedRef = useRef(gameFinished);
  gameFinishedRef.current = gameFinished;

  // Scoreboard
  const [currentScoreboardList, setCurrentScoreboardList] = useState(null);
  useEffect(() => {
    fetch('https://5sszauy1l3.execute-api.us-west-1.amazonaws.com/prod/scores')
      .then(response => response.json())
      .then(data => setCurrentScoreboardList(data.Items));
  }, []);

  // Add key event listener
  useEffect(() => {
    // Checks if all pressedKeys except the given ones are false.
    const areExactKeysPressed = (...letters) => {
      const keyCodesThatMustBeTrue = letters.map(l => `Key${l}`);
      return Object.entries(pressedKeys.current).every(([keyCode, isPressed]) => keyCodesThatMustBeTrue.includes(keyCode) ? isPressed : !isPressed);
    }

    const handleKeyDown = (e) => {
      if (Object.keys(pressedKeys.current).includes(e.code)) {
        pressedKeys.current[e.code] = true;

        let declaredDanceMove = null;
        if (areExactKeysPressed('S')) {
          declaredDanceMove = new DanceMove(MoveTypes.BASIC);
        } else if (areExactKeysPressed('D', 'Q')) {
          declaredDanceMove = new DanceMove(MoveTypes.FOLLOW_TURN);
        } else if (areExactKeysPressed('A', 'D')) {
          declaredDanceMove = new DanceMove(MoveTypes.SWINGOUT);
        } else if (areExactKeysPressed('A', 'E')) {
          declaredDanceMove = new DanceMove(MoveTypes.MINNIE_DIP);
        } else if (areExactKeysPressed('Q', 'W', 'E')) {
          declaredDanceMove = new DanceMove(MoveTypes.FLOURISH);
        }

        if (declaredDanceMove !== null) {
          setCurrentMove(declaredDanceMove);
          const NOW = Date.now();
          setCurrentMoveStartTime(NOW);

          // Was move correct? If so, increase score; if not, play dud sound effect
          if (gameStartTimeRef.current !== null && !gameFinishedRef.current) {
            const targetMove = plannedMoves.current.filter(plannedMove => Math.abs(plannedMove.intendedCollisionTimestamp - NOW) / 1000 < 0.3 && !plannedMove.alreadyHit)[0];
            if (!targetMove || targetMove.danceMove.moveType !== declaredDanceMove.moveType) {
              missSFX.pause();
              missSFX.currentTime = 0.0;
              missSFX.play();
            } else {
              setScore(s => s + 1);
              targetMove.alreadyHit = true;

              hitSFX.pause();
              hitSFX.currentTime = 0.0;
              hitSFX.play();
            }
          }
        }
      }
    };
    const handleKeyUp = (e) => {
      if (Object.keys(pressedKeys.current).includes(e.code)) {
        pressedKeys.current[e.code] = false;
      }
    }

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);




  // One function to begin game
  function beginGame() {
    const NOW = Date.now();

    setGameFinished(false);
    gameTimeoutIdRef.current = setTimeout(() => {
      if (gameStartTimeRef.current) {
        setGameFinished(true);
      }
    }, 172500);

    setScore(0);

    // Come up with list of moves and their intended collision times
    const listOfCollisionTimes = new Array(TOTAL_NUMBER_OF_MOVES).fill(0)
      .map(() => 166 * (1 - Math.pow(Math.random(), 1.25)))
      .map(t => t < 33 ? t + 33 + t*3 : t);
    listOfCollisionTimes.sort(function(a, b){return a-b});
    listOfCollisionTimes[0] = 10;
    listOfCollisionTimes[1] = 16;
    listOfCollisionTimes[2] = 23;
    listOfCollisionTimes[3] = 25;
    listOfCollisionTimes[4] = 30;
    for (let i = 5; i < listOfCollisionTimes.length; ++i) {
      if (listOfCollisionTimes[i-1] > listOfCollisionTimes[i]) {
        listOfCollisionTimes[i] = listOfCollisionTimes[i] + 1;
      } else if (listOfCollisionTimes[i-1] > listOfCollisionTimes[i] - 0.5) {
        listOfCollisionTimes[i] = listOfCollisionTimes[i] + 0.5;
      }
    }
    const pm = [ 
      { danceMove: new DanceMove(MoveTypes.BASIC), intendedCollisionTimestamp: new Date(+NOW + listOfCollisionTimes[0] * 1000) },
      { danceMove: new DanceMove(MoveTypes.FOLLOW_TURN), intendedCollisionTimestamp: new Date(+NOW + listOfCollisionTimes[1] * 1000) },
      { danceMove: new DanceMove(MoveTypes.BASIC), intendedCollisionTimestamp: new Date(+NOW + listOfCollisionTimes[2] * 1000) },
      { danceMove: new DanceMove(MoveTypes.SWINGOUT), intendedCollisionTimestamp: new Date(+NOW + listOfCollisionTimes[3] * 1000) },
      { danceMove: new DanceMove(MoveTypes.MINNIE_DIP), intendedCollisionTimestamp: new Date(+NOW + listOfCollisionTimes[4] * 1000) },
    ];
    for (let i = pm.length; i < TOTAL_NUMBER_OF_MOVES-1; ++i) {
      pm.push({
        danceMove: DanceMove.newDanceMove({not: pm[i-1].danceMove.moveType}),
        intendedCollisionTimestamp: new Date(+NOW + listOfCollisionTimes[i] * 1000),
      });
    }
    pm.push({ // last move = flourish
      danceMove: new DanceMove(MoveTypes.FLOURISH),
      intendedCollisionTimestamp: new Date(+NOW + 171 * 1000),
    });
    plannedMoves.current = pm;
    
    audio.pause();
    audio.currentTime = 0;
    audio.play();
    setGameStartTime(NOW);
  }
  



  return (
    <div style={{
      width: STAGE_WIDTH + "px",
      height: STAGE_HEIGHT + "px",
      borderStyle: "solid",
      backgroundImage: "url(/idaNoyes.jpg)",
      backgroundSize: "100% 100%",
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      marginBottom: "100px",
    }}>

      <Hero
        currentMove={currentMove}
        style={{
          position: "absolute",
          bottom: "10%",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      />

      <Button 
        variant="success"
        hidden={gameStartTime}
        onClick={beginGame}
        style={{
          position: "absolute",
          top: "35%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "10px 20px",
          borderRadius: "10px",
          borderStyle: "solid",
          borderColor: "#3ba635",
          cursor: "pointer",
          fontFamily: "Supermercado One",
          fontSize: "24px",
        }}
      >
        {isChinese ? '一起跳舞！' : 'Let\'s Lindy!'}
      </Button>

      {gameStartTime ? (
        <>
          <Timeline
            width={STAGE_WIDTH * 0.8}
            speed={TIMELINE_SPEED}
            plannedMoves={plannedMoves.current}
            gameStartTime={gameStartTime}
            moveActivated={Math.abs(currentMoveStartTime - Date.now()) / 100 < 0.1}
          />

          <span
            style={{
              position: "absolute",
              right: "18%",
              bottom: "17%",
              fontSize: "40px",
              color: "#71e657",
              fontFamily: "Supermercado One"
            }}
          >
            {`${score}`}
          </span>
          <span
            style={{
              position: "absolute",
              right: "16%",
              bottom: "14%",
              fontSize: "40px",
              color: "#71e657",
              fontFamily: "Supermercado One",
            }}
          >/</span>
          <span
            style={{
              position: "absolute",
              right: "8%",
              bottom: "11%",
              fontSize: "40px",
              color: "#71e657",
              fontFamily: "Supermercado One",
            }}
          >100</span>
        </>
      ) : (
        <>
          <div
            className="text-center"
            style={{
              position: "absolute",
              top: "7%",
              left: "50%",
              width: "80%",
              transform: "translateX(-50%)",
              backgroundColor: "rgb(242,205,124,0.85)",
              borderRadius: "7px",
              padding: "3px",
            }}
          >
            Press the highlighted keys to try a new move!<br />
            When you're ready, click "{isChinese ? '一起跳舞！' : 'Let\'s Lindy!'}"
          </div>
          <Cue
            danceMove={new DanceMove(MoveTypes.BASIC)}
            fixedX="20%"
            fixedY="30%"
            scale="1.2"
            showBackground={true}
          />
          <Cue
            danceMove={new DanceMove(MoveTypes.FOLLOW_TURN)}
            fixedX="20%"
            fixedY="66%"
            scale="1.2"
            showBackground={true}
          />
          <Cue
            danceMove={new DanceMove(MoveTypes.SWINGOUT)}
            fixedX="80%"
            fixedY="30%"
            scale="1.2"
            showBackground={true}
          />
          <Cue
            danceMove={new DanceMove(MoveTypes.MINNIE_DIP)}
            fixedX="80%"
            fixedY="66%"
            scale="1.2"
            showBackground={true}
          />
        </>
      )}

      <Form
        className={(gameFinished && !scoreSubmitted ? "fadeIn" : "fadeOut") + " text-center"}
        onSubmit={(e) => {
          e.preventDefault();
          if (displayName) {
            setScoreSubmitted(true);

            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                score: score,
                displayName: displayName,
                timestamp: Date.now(),
              }),
            };
            fetch('https://5sszauy1l3.execute-api.us-west-1.amazonaws.com/prod/scores', requestOptions)
              .then(() => {
                // Refresh scoreboard
                fetch('https://5sszauy1l3.execute-api.us-west-1.amazonaws.com/prod/scores')
                  .then(response => response.json())
                  .then(data => setCurrentScoreboardList(data.Items));
              });
          }
        }}
        style={{
          position: "absolute",
          left: "7%",
          top: "30%",
          fontSize: "40px",
          color: "white",
          fontFamily: "Supermercado One",
          backgroundColor: "rgba(52,128,235,0.7)",
          width: "25%",
          borderRadius: "4px",
        }}
      >
        <h3 style={{marginTop: "8px", fontFamily: "Supermercado One"}}>Nice job!</h3>
        <h6 style={{fontFamily: "Supermercado One"}}>You nailed {score} of 100 moves!</h6>

        <Form.Control type="text" placeholder="Enter name" maxLength={11} onChange={e => {
          setDisplayName(e.target.value);
        }} />
        <Button
          type="submit"
        >
          Post my score!
        </Button>
      </Form>

      <Button
        variant="success"
        className={gameStartTime !== null ? "fadeIn" : "fadeOut"}
        onClick={() => {
          setGameStartTime(null);
          setGameFinished(false);
          setScoreSubmitted(false);
          setScore(0);
          clearTimeout(gameTimeoutIdRef.current);
          gameTimeoutIdRef.current = null;
          audio.pause();
          audio.currentTime = 0;
        }}
        style={{
          position: "absolute",
          right: "3%",
          bottom: "3%",
          fontFamily: "Supermercado One",
          lineHeight: "8%",
          width: "20%",
          height: "8%",
        }}
      >
        Reset
      </Button>


      
      <div style={{
        position: 'absolute',
        left: '93%',
        top: '15%',
        fontFamily: 'Supermercado One',
        width: '20%',
        height: '70%',
        backgroundColor: 'orange',
        borderRadius: "6px",
        borderColor: "black",
        borderWidth: "2px",
        borderStyle: "solid",
      }}>
        <div className='text-center'>{isChinese ? '記分牌' : 'Scoreboard'}</div>

        <ListGroup variant='flush' style={{fontFamily: "Quicksand", borderRadius: "4px", height: "91%", overflow: 'auto'}}>
          {currentScoreboardList ? (
            [...currentScoreboardList].sort((a, b) => parseInt(b.score.N) - parseInt(a.score.N)).map(item => {
              return (
                <ListGroup.Item className='p-0 px-1 d-flex' key={`scoreboard-${item.id.S}`}>
                  <span>{item.displayName.S}</span>
                  <span className='ml-auto'>{item.score.N}</span>
                </ListGroup.Item>
              );
            })
          ) : (
            <span>Loading...</span>
          )}
        </ListGroup>
      </div>
      
    </div>
  );
}