import React from 'react';


function QuestionCard({
  question,
  answer,
}) {
  return (
    <div>
      <h5>{question}</h5>
      <div>{answer}</div>
    </div>
  );
}



export default function FAQ() {

  return (
    <div className='mb-5'>
      <h2>FAQs</h2>
      <p className="subtitle">
        We've answered some frequent questions on this page, but, as always, feel free to drop us a line!
      </p>

      <hr />

      <QuestionCard 
        question='Is there a dress code?'
        answer={
          <p>
            Wear whatever makes you feel most comfortable!
            If you'd prefer some guidance, we think semi-formal (or "dressy casual") attire is probably
            a good fit (think: optional ties or suit jackets, summer dresses, floral prints, anything!).
            We also don't have a defined color scheme, so have fun with your colors.
            Most importantly, wear something you'd feel comfortable dancing and having a good time in!
          </p>
        } 
      />

      <QuestionCard
        question='Do you have a registry?'
        answer={
          <p>
            Actually, we don't, and we aren't expecting gifts!
            We appreciate your thoughtfulness, but we are genuinely sure
            that, after the last two years, the best and most meaningful present we can have is
            a safe and memorable wedding celebration with all of you.
            Plus, we're already stretching our cabinet/apartment space :)
          </p>
        }
      />

      <QuestionCard
        question="No, really, we want to get you something and we won't take no for an answer!"
        answer={
          <p>
            Really, having our wedding celebration at last is 100% enough for us, and we mean it!
            Most of you will be traveling in to Chicago already and that means the world to us.
            But for those of you who won't take no for an answer, let us know and we can coordinate,
            as boxed gifts on the day might be a little challenging for us to bring home. 
            Cash contributions toward our honeymoon are also welcome but definitely not expected.
            Above all, your presence is what's most important to us!
          </p>
        }
      />
      

      <QuestionCard
        question='Will you have any Covid safety protocols?'
        answer={
          <>
            <p>
              Yes! We are reaching out to ask all guests to take a Covid test prior to traveling,
              or in the three days before the wedding. We're inviting our nearest and dearest
              to our celebration, so we want to minimize the risk as much as possible.
            </p>
            <p>
              As for masks, we don't have any requirements, but you are absolutely welcome to wear a mask if you choose.
            </p>
            <p>
              Please let us know if there are any special situations we should be aware of for you or your family.
              It's been a long time since we've been able to gather in person, and we want to make sure that we
              celebrate in the safest way possible. Reach out to us if you have any additional Covid questions!
            </p>
          </>
        }
      />

      <QuestionCard
        question='Will there be swing dancing?'
        answer={
          <p>
            Of course there will (as well as other dancing/music)! We are even planning to teach a fifteen-minute crash
            course for all who want to join the fun!
          </p>
        }
      />

      <QuestionCard
        question='What will the Zoom portion of the wedding be like?'
        answer={
          <p>
            For our virtual guests, we will send out an email with the Zoom link in the week leading up to the celebration.
            The live stream will begin a few minutes before our ceremony at 4pm Central Time, and 
            last around thirty minutes. We'll come over and mingle virtually right after the ceremony, so that 
            we all get a chance to see each other before folks sign off!
          </p>
        }
      />
    </div>
  );


}
