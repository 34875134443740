import React from 'react';
import { SpriteAnimator } from 'react-sprite-animator';
import Cue from './Cue';
import heart from './heart.svg';




export default function Timeline({
  width,
  speed,
  plannedMoves,
  gameStartTime,
  moveActivated = false,
  ...props
}) {

  return (
    <div 
      style={{
        position: "absolute",
        top: "50px",
        left: "50%",
        transform: "translateX(-50%)",
        width: width,
        height: "6px",
        borderRadius: "3px",
        backgroundImage: "linear-gradient(to right, rgba(0,190,100,1), rgba(0,190,100,1), rgba(0,190,100,0))",
      }}
      {...props}
    >

      {plannedMoves.map(({danceMove, intendedCollisionTimestamp}, idx) => {
        return (<Cue
          key={`cue${idx}`}
          danceMove={danceMove}
          intendedCollisionTimestamp={intendedCollisionTimestamp}
          speed={speed}
        />);
      })}
      
      <div
        style={{
          position: "absolute",
          left: "10%",
          top: "3px",
          transform: "translate(-50%, -50%)"
        }}
      >
        <SpriteAnimator
          width={36}
          height={36}
          scale={0.5}
          sprite={heart}
          shouldAnimate={moveActivated}
          fps={60}
          startFrame={0}
          stopLastFrame={true}
          reset={!moveActivated}
        />
      </div>


    </div>
  );
}