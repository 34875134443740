import React from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import { useLanguage } from "../App";

export default function OurStory() {

  const { isChinese } = useLanguage();

  const carouselCaptions = !isChinese ? [
    'Crashing the Logan O-Party, definitely not as first years',
    'Blue skies all around when we\'re together!',
    'Puerto Rico with Kevin\'s family one spring break',
    'May\'s graduation in 2019 - Kevin got a Zoom one a year later',
    'Java Jive with our favorite partners',
    'May loves the beach! Even if it\'s way too cold',
    'Hiking in May\'s hometown, Hong Kong',
    'A rare Christmas Java Jive for Kevin, who always had orchestra concerts',
    'Our new home here in Berkeley!',
    'Taiwan with May\'s family one winter break',
    'Camping in Wisconsin!',
  ] : [
    '芝加哥大學',
    '舊金山',
    '波多黎各',
    '鴻霙的畢業典禮',
    '搖擺舞',
    '聖塔克魯茲',
    '香港的寶馬山',
    '聖誕節舞會',
    '伯克利',
    '台灣的西堤牛排',
    '威斯康星',
  ];


  return (
    <Container>
      <Row>
        <Col xs={12} md={6} style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <Carousel className="m-3 w-100">
            {
              carouselCaptions.map((caption, idx) => {
                return (<Carousel.Item key={"carouselImg"+idx}>
                  <img
                    src={require(`./carousel-imgs/mk-${idx+1 < 10 ? "0" : ""}${idx+1}.jpg`)}
                    alt={caption}
                    style={{
                      display: "block",
                      height: "auto",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                  />
                  <Carousel.Caption>
                    <p>{caption}</p>
                  </Carousel.Caption>
                </Carousel.Item>);
              })
            }
          </Carousel>
        </Col>

        <Col xs={12} md={6} className="overflow-auto" style={{ maxHeight: "70vh" }}>
          {isChinese ? (
            <>
              <p>
                歡迎來到我們的網頁！我們是 2016 年認識的，當時松濤是芝加哥大學的大一新生，而鴻霙剛開始讀大二。
              </p>
              <p>
                因為我們住同一間宿舍（ Halperin），我們很快就成了朋友，也發現了很多共同點：我們喜歡早起；從小就開始拉小提琴；彼此性格隨和，平易近人。
                後來，松濤參加了鴻霙帶領的搖擺舞社團，交往的過程讓我們意識到彼此的心靈相契。就這樣，我們決定在完成學業後攜手共度人生，互為相知相惜的伴侶。
              </p>
              <p>
                2020 年 5 月 31 日，在疫情的困境中，我們宣布訂婚；那年 6月18日，我們在芝加哥的一家法院結婚，之後為了工作而遷居加州柏克萊市。
                現在，松濤是肉製品公司 Upside Foods 的研究員，而鴻霙在舊金山的 Highwire PR 公关公司工作，也從事文學翻譯。有空的時候，我們喜歡一起看電視、煮飯、最近也開始嘗試滑雪！
              </p>
              <p>
                我們很感激因為彼此認識，所以在芝加哥、香港、台灣、大陸、加拿大、阿拉巴馬州、等地方都有親戚和朋友。
                2022 年 6 月 11 日，我們將在芝加哥舉行我們的婚禮，希望能到時候見面！
              </p>
            </>
          ) : (
            <>
              <p>
                Our story goes back to 2016, when Kevin was a first-year at the University of Chicago and May was a second year. 
              </p>
              <p>
                Living in the same house on campus (go Halperin!), we quickly became friends.
                We discovered a lot in common: we were both early birds, often the first two at breakfast on Sunday mornings;
                we both grew up playing violin; we were relaxed and easygoing; we enjoyed wordplay; and we liked V8.
                And, when Kevin heard that May was the one to go to for information about the swing dance club, our fate was pretty much sealed 
                (before we'd even met, May was in the orientation-week swing dance performance that got Kevin interested!).
                Three quarters later, on May 31, 2017, we went on our first “date” to the Promontory Point after another swing performance,
                and we've been inseparable ever since. 
              </p>
              <p>
                On May 31, 2020, exactly three years after we first started dating, we announced our engagement. 
                With no end in sight to the pandemic, we got married in a Chicagoland courthouse that June before moving out to Berkeley, California
                to begin our new lives together.
              </p>
              <p>
                By day, Kevin is a researcher at Upside Foods, a cultivated meat company, and May works at Highwire PR while balancing her passion for translation. 
                In our free time, we just enjoy spending time with each other, whether it's making crosswords, cooking, or watching TV.
              </p>
              <p>
                We're grateful not only to each other, but to the extended family and friends we now share, with loved ones in Chicago as well as from
                Hong Kong, Taiwan, China, Canada, Alabama, Pennsylvania, California, and more. On June 11, 2022, we'll be 
                celebrating our union in Chicago, where we have so many fond memories, and we hope you'll join us there (or virtually)!
              </p>
            </>
          )}

        </Col>
      </Row>

    </Container>
  );
  
}
