import React from 'react';
import { Card, Container, Col, Row, Tab, Nav } from 'react-bootstrap';
import { useLanguage } from '../App';


function RecCard({
  imgSrc,
  cardTitle,
  cardText
}) {
  return (
    <Col xs="12" md="6" lg="4" xl="3" className="mb-3">
      <Card>
        <img 
          className="w-100"
          src={imgSrc}
          alt={imgSrc}
          style={{ maxWidth: "none" }}
        /> 
        <Card.Body>
          <Card.Title>{cardTitle}</Card.Title> 
          <Card.Text>{cardText}</Card.Text> 
        </Card.Body> 
      </Card> 
    </Col>
  )
}


export default function Chicago() {

  const { isChinese } = useLanguage();

  return (
    <div className="mb-5">
      <div>
        <h2>{isChinese ? '芝加哥介紹' : 'Travel Tips'}</h2>
        <p className="subtitle" hidden={isChinese}>
          For those coming from out of town, here are some tips about getting around the city.
        </p>

        <div style={{
            borderStyle: "solid",
            borderColor: "darkblue",
            borderWidth: "1px",
            borderRadius: "5px",
            padding: "8px 10px",
            backgroundColor: "rgb(207, 248, 250, 0.5)",
            marginBottom: "1rem",
        }}>
          <Tab.Container defaultActiveKey="venue">
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="venue">{isChinese ? '地點' : 'Venue Access'}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="transportation">{isChinese ? '交通' : 'Chicago Transit'}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="accommodation">{isChinese ? '住宿' : 'Accommodation'}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="airports">{isChinese ? '機場' : 'Airports'}</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="venue">
                    {isChinese ? (
                      <p>
                        我們選擇的場地在於芝加哥的東部。
                        建於1872年，我們的場地是一個具有歷史性的建築：它在1871的芝加哥大火發生之後而建立，最初作為家具工廠，提供材料給當地的商人重建被燒毀的商店。
                        今天，它是許多人喜歡來舉行婚禮和派對的地方，你從頂樓也能看到聯合中心，芝加哥公牛的主場！
                      </p>
                    ) : (
                      <>
                        <p>
                          Our wedding celebration will be held just west of the West Loop neighborhood and near United Center,
                          about 10-15 minutes from downtown by car, or a 12 minute walk from the Ashland Green &amp; Pink Line station.
                          While the neighborhood is pretty safe, the area can feel slightly industrial and, as in any big city, please
                          remain together and aware if walking at night. Rideshares like Lyft and Uber are great options for after
                          the reception.
                        </p>
                        <p>
                          While the venue's parking lot is restricted, street parking near the venue should also be available for free on the weekends
                          for those driving in.
                        </p>
                      </>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="transportation">
                    {isChinese ? (
                      <p>
                        達 Uber 或 Lyft 來我們的場地最方便。
                        不過，如果你想經驗一下芝加哥的捷運 （簡稱 “the L"), 我們的場地離捷運綠線的 Ashland 站很近 （步行12 分鐘）。
                        當然，美國的公共交通還是比不上香港的MTR或台灣的MRT！
                      </p>
                    ) : (
                      <>
                        <p>
                          Chicago's public transit system, the CTA, consists of trains (the "L") and a network of buses. Many of the city's attractions
                          can be accessed via one of the train lines, which are color-coded and radiate outward from downtown.
                          Google Maps is pretty accurate at showing routes via the CTA, along with arrival times and any delays.
                          While Chicago's CTA can't quite compare to the speed of, say, Hong Kong transit, it can be
                          the most convenient way to get places nearby the train routes, especially downtown.
                        </p>
                        <p>
                          Much of the city is quite walkable (and flat!), particularly in and around downtown and the more central neighborhoods.
                          As you travel further outward, particularly west or south, things can become more spread out and difficult to access without a car.
                          Rideshares should be readily accessible throughout the city.
                        </p>
                        <p>
                          If you're staying in or visiting the suburbs, there's a different train system, the Metra, that will bring you into the city
                          where you can transfer onto the CTA.
                        </p>
                      </>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="accommodation">
                    {isChinese ? (
                      <p>
                        芝加哥的酒店和 AirBnB 不少，若是對於住宿有任何問題的話（比如安全考慮、觀光景點、餐廳），
                        可以隨時問我們！大部分的朋友會選擇住在大學附近，或者芝加哥的“洛普區” （The Loop) ，可以比較接近芝加哥的旅遊景點！
                      </p>
                    ) : (
                      <p>
                        There are plenty of hotels and AirBnBs in the city to choose from, depending on what you're looking for.
                        Locations more centrally located to Chicago's Loop area (downtown) will likely be most accessible by train, 
                        but because Chicago's streets are a straight-up grid, most areas will be fairly convenient by car.
                        There are many neighborhoods with great character, attractions, and nightlife - we would be happy to help offer
                        suggestions or try to answer any questions you have about finding accommodations!
                      </p>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="airports">
                    {isChinese ? (
                      <p>
                        奥黑尔國際機場（O'Hare International Airport / ORD) 是芝加哥市的主要機場，位於市中心西北27公里。
                      </p>
                    ) : (
                      <p>
                        Chicago has two airports, O'Hare and Midway. Depending on traffic when you arrive, it may be quickest (and cheapest) to take public transit,
                        which runs express routes to both airports from downtown. If you want a bite on the way in or out, 
                        there are many interesting restaurants in both airports' neighborhoods.
                      </p>
                    )}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>

      <div>
        <h2>{isChinese ? '風城一周遊' : 'Recommendations in the City'}</h2>
        <p class="subtitle" hidden={isChinese}>
          We're proud to have our celebration in Chicago.
          While you're here, check out some of our favorite places around town!
        </p>
      </div>

      <Container>
        <Row>
          <RecCard
            imgSrc="/recommendations/boattour.jpg"
            cardTitle={isChinese ? '觀光遊輪' : "Cruise through a River Architecture tour"}
            cardText={isChinese ? '從水中探索風城，體驗芝加哥的傳奇建築和傑出的建築師！' : `Downtown Chicago is famous for its architecture. What better way
            to appreciate it than by boat? And for those who like to work for their architectural tours,
            Kevin recommends the kayak versions!`}
          />

          <RecCard
            imgSrc="/recommendations/wicker.jpg"
            cardTitle={isChinese ? '芝加哥地區' : "Immerse yourself in Chicago's vibrant neighborhoods"}
            cardText={isChinese ? '建議大家去Pilsen享受道地的墨西哥料理，和文藝青年喜愛的Wicker Park逛街！' : `
              Perhaps Chicago's most unique strength is the richness and diversity of its various
              neighborhoods. Try Wicker Park or River North for a late-night afterparty,
              Little Village or Pilsen for some of Kevin's favorite food, Devon for delicious dosa,
              Greektown for fiery cheese, or one of the 70+ other neighborhoods for just about any experience you 
              could imagine.
            `}
          />

          <RecCard
            imgSrc="/recommendations/portillos.jpg"
            cardTitle={isChinese ? '吃喝玩樂' : "Consume classic Chicago cuisine"}
            cardText={isChinese ? '來到芝加哥，就一定要嘗試我們的“深盤披薩” （又叫 “厚底薄餅”）！' : `
              Chicago has so much incredible, famous food to offer.
              Grab a Chicago-style hot dog at Portillo's, fried chicken at Harold's,
              barbeque ribs at Lem's, or one of the many other foods that represent this city.
            `}
          />

          <RecCard
            imgSrc="/recommendations/museum.jpg"
            cardTitle={isChinese ? '博物館一日遊' : "Marvel at a museum"}
            cardText={isChinese ? '海德公園最出眾的文化機構就是芝加哥大學以及我們當學生經常去的科學及工業博物館！' : `
              From the Museum of Science and Industry to the International Museum of Surgical Science, 
              we've both enjoyed museums from the grand & majestic to the niche & engaging.
              Kids especially may enjoy Adler Planetarium, Shedd Aquarium, and the Field Museum, which
              all overlook the lake near downtown.
            `}
          />

          <RecCard
            imgSrc="/recommendations/chinatown.jpg"
            cardTitle={isChinese ? '中國城' : "Check out Chinatown"}
            cardText={isChinese ? '芝加哥中國城位於芝加哥北面的盔甲廣場社區，是美國古老的華人社區。' : `
              Just 20 minutes away from campus, Chinatown was a frequent destination in college!
              We'll bring some Chinatown options to our wedding from Chiu Quon Bakery and MingHin Cuisine, 
              but other favorites of ours are Slurp Slurp Noodles, Hing Kee, and QXY.
            `}
          />

          <RecCard
            imgSrc="/recommendations/thebean.jpg"
            cardTitle={isChinese ? '雲門' : "Take a picture under The Bean"}
            cardText={isChinese ? 'Kevin 曾經從大學宿舍慢跑9公里到芝加哥的“雲門”雕塑 （因其外形，它又被稱為“豆子” ）。' : `
              Every now and then, Kevin would run the nine miles from our college dorm to the Bean (also known as Cloud Gate).
              The lakeshore trail in summertime is a great place to walk, bike, or jog, especially if you're a morning person (before it gets too hot).
            `}
          />
          
          <RecCard
            imgSrc="/recommendations/artinstitute.jpg"
            cardTitle={isChinese ? '欣賞藝術' : "Visit the Art Institute"}
            cardText={isChinese ? '作為美國第二大藝術館, 芝加哥的藝術博物館值得去一趟！' : `
              While you're there, be sure to check out Grant Wood's American Gothic,
              Edward Hopper's Nighthawks, and Archibald Motley's Nightlife (May's favorite)!
            `}
          />

          <RecCard
            imgSrc="/recommendations/sears.png"
            cardTitle={isChinese ? '威利斯大廈 ' : "Scale the Sears (Willis) Tower"}
            cardText={isChinese ? '你知道嗎：樓高442.3米的威利斯大廈 （故稱西爾斯大樓）曾是世界上最高大樓！' : `
              On a clear day, you can see four states from the top of Chicago's tallest skyscraper.
              The glass Skydeck at the top can get crowded and may be a little pricey, but it's your chance to be
              on top of the world!
            `}
          />

        </Row>
      </Container>
    </div>
  );
    }
