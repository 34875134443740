import React, { createContext, useContext, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Routes from "./Routes";
import "./App.css";
import { Button, ButtonGroup } from "react-bootstrap";

function getLocalStorageIsChinese() {
  return localStorage.getItem('SelectedChinese') === 'true' || false;
}
function setLocalStorageIsChinese(isChinese) {
  localStorage.setItem('SelectedChinese', isChinese);
}

const LanguageContext = createContext(null);
export const useLanguage = () => {
  return useContext(LanguageContext);
}

function App() {

  const [isChinese, setIsChinese] = useState(getLocalStorageIsChinese());

  return (
    <div className="App container py-3">
      <Navbar collapseOnSelect expand="md" className="mb-3">
        <Navbar.Brand href="/" className="font-weight-bold text-muted">
          {isChinese ? '黃鴻霙' : 'May'} &amp; {isChinese ? '松涛' : 'Kevin'}
        </Navbar.Brand>
        <Navbar.Toggle />
        <ButtonGroup size="sm" className="ml-2">
          <Button
            variant={(isChinese ? "outline-" : "") + "secondary"}
            onClick={() => {
              setIsChinese(false);
              setLocalStorageIsChinese(false);
            }}
          >
            English
          </Button>
          <Button
            variant={(isChinese ? "" : "outline-") + "secondary"}
            onClick={() => {
              setIsChinese(true);
              setLocalStorageIsChinese(true);
            }}
          >
            中文
          </Button>
        </ButtonGroup>
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <Nav.Link href="/story">{isChinese ? '關於我們' : 'Our Story'}</Nav.Link>
            <Nav.Link href="/rsvp">RSVP</Nav.Link>
            <Nav.Link href="/chicago">{isChinese ? '芝加哥' : 'Chicago'}</Nav.Link>
            <Nav.Link href="/faq">FAQs</Nav.Link>
            <Nav.Link href="/justforfun">{isChinese ? '其它' : 'Just For Fun'}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <LanguageContext.Provider value={{isChinese}}>
        <Routes />
      </LanguageContext.Provider>
    </div>
  );
}

export default App;
