import React from 'react';
import { SpriteAnimator } from 'react-sprite-animator';


/**
 * Wrapper for an animated Sprite.
 */
export default function Hero({
  currentMove,
  ...props
}) {
  
  return (
    <div {...props}>
      <SpriteAnimator
        width={400}
        height={600}
        scale={2}
        sprite={currentMove.spritesheetSrc}
        shouldAnimate={true}
        fps={8}
        startFrame={0}
        stopLastFrame={false}
      />
    </div>
  );
}